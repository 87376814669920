/* Gallery Container */

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.mentions-heading {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    text-align: center;
    font-size:3rem;
    margin-bottom: 1rem;
}


/* Gallery Grid */

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}


/* Individual Image Container */

.gallery-item {
    position: relative;
    height: fit-content;
    border: 2px solid #333;
    /* Border color and width */
    overflow: hidden;
    transition: transform 0.2s ease;
    width: calc(25% - 40px);
    /* Each row contains four images, adjust as needed */
}

    @media screen and (max-width: 768px) {
        .gallery-item{
        width: calc(33.33% - 40px);
        text-align: center;
        /* Adjust for three images per row on smaller screens */
    }
}

    @media screen and (max-width: 480px) {
        .gallery-item{
        width: calc(50% - 40px);
        text-align: center;
        /* Adjust for two images per row on even smaller screens */
    }
    }
    
    /* Center text horizontally */



/* Image */

.gallery-image {
    width: 100%;
    /* height: 50vh; */
    aspect-ratio: 1/1;
    display: block;
    object-fit: cover;
    /* Ensure the entire image fits within the container */
}


/* Description Text */

.image-description {
    padding: 10px;
    background: #0551A1;
    color: #fff;
}


/* Hover Effect */

.gallery-item:hover {
    transform: scale(1.05);
    /* Scale up the image on hover */
    border-color: #0551A1;
    /* Change border color on hover */
}


/* Modal */

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 999;
}


/* Modal Image */

.modal-image {
    display: flex;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border: 2px solid #fff;
    /* Border for modal image */
}


/* Modal Description Text */

.modal-description {
    display: flex;
    padding: 10px;
    background: #07A0C3;
    color: #fff;
}


/* Close Button */

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

@media (max-width:400px) {
    .mentions-heading {
        font-size: 2rem;
    }
}