.footer_text {
    display: flex;
    font-size: 1rem;
    margin-top: 3rem;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 0.3rem;
}

@media (max-width: 1000px) {
    .footer_text {
        flex-direction: column;
        margin-top: 2rem;
        font-size: 0.7rem;
        align-items: center;
        gap: 0.5rem;
    }
}