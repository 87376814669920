* {
    margin: 0;
    padding: 0;
}

.Facilities {
    margin: 90px 0px;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading_fac {
    display: flex;
    /* top: 100px; */
    flex-direction: column;
    align-items: center;
    font-family: Lato;
    font-size: 64px;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header_content {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    gap: 5rem;
    /* align-items: flex-start; */
}

.snf_image {
    display: flex;
    /* border-radius: 20px; */
    /* margin-left: 43px; */
}

.snf_content {
    display: flex;
    flex-direction: column;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
    color: #000000;
}

.apt_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}


/* General Facilities ----------------------------------- */

.general-fac {
    display: flex;
    flex-direction: column;
}

.facilities-headings h1 {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}

.highlight {
    font-family: Lato;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2rem;
}

.facilities-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 4rem;
}

.facilities-list li {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
}

.col-1 ul,
.col-2 ul,
.col-3 ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


/* Special Surgery BOXES ------------------------------------*/

.all-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90vw;
    margin: 0 auto;
}

.all-boxes a {
    white-space: pre-wrap;
    text-decoration: none;
}

.box {
    flex: 0 0 calc(220px + 30px);
    height: 100px;
    padding: 20px;
    gap: 10px;
    background: rgba(186, 247, 248, 0.5);
    border: 0.5px solid #07A0C3;
    border-radius: 10px;
    margin: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.box-text {
    font-family: Lato;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #07A0C3;
    white-space: pre-wrap;
}

.box:hover .box-text {
    color: white;
}

.box:hover {
    background-color: #07A0C3;
}


/* Paragraph of every boxes------------------------------------------------------------------------------------------- */

.surgeries-para {
    margin-top: 2rem;
}

.surgery-para-head {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    /* justify-content: center; */
}

.surgery-para-head h1 {
    display: flex;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-top: 0px;
    white-space: wrap;
    /* margin-left: 5rem; */
    width: 90vw;
    margin: auto;
}

.underlined-heading {
    /* margin-left: auto; */
    margin-left: 3.8rem;
    margin-right: auto;
    width: 300px;
    height: 0px;
    border: 2px solid #07A0C3;
    margin-top: 5px;
}

.know-more a {
    text-decoration: none;
    display: flex;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #07A0C3;
    white-space: nowrap;
    margin-right: 59px;
}

.surgery-details {
    display: flex;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    gap: 0.5rem;
}

.surgery-details img {
    width: 300px;
    height: 300px;
}

.para-details p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
    text-align: justify;
}

.para-details h2 {
    margin: 1rem 0;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
}


/* for phone */

@media (max-width: 1024px) {
    .heading_fac {
        font-size: 48px;
    }
    .header_content {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        gap: 2rem;
    }
    .snf_content {
        font-size: 14px;
        line-height: 20px;
    }
    .snf_image img {
        justify-content: center;
        margin: auto;
        width: 300px;
        height: 300px;
    }
    .facilities-headings h1 {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        font-family: Lato;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
    }
    .highlight {
        font-size: 40px;
        line-height: 48px;
        margin-left: 1rem;
    }
    .facilities-list li {
        font-size: 16px;
        line-height: 20px;
    }
    .facilities-list {
        flex-direction: column;
        text-align: center;
        margin: 0;
        padding: 0;
        gap: 1rem;
        margin-top: 1rem;
    }
    .col-1 ul,
    .col-2 ul,
    .col-3 ul {
        gap: 1rem;
        width: 25vw;
        margin-left: auto;
        margin-right: auto;
        align-items: flex-start;
        /* flex-direction: column; */
    }
    .surgery-para-head h1 {
        font-size: 18px;
    }
    .surgery-details {
        display: flex;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
    }
    .para-details p {
        font-size: 14px;
        line-height: 20px;
    }
    .para-details h2 {
        margin: 1rem 0;
        font-size: 14px;
        line-height: 20px;
    }
    .know-more a {
        font-size: 14px;
        line-height: 20px;
        margin: 1rem 0;
    }
    .surgery-details img {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 768px) {
    .surgery-details img {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .surgery-details {
        flex-direction: column;
    }
    .underlined-heading {
        margin-left: 2.5rem;
    }
}

@media (max-width: 550px) {
    .header-sec {
        gap: 0rem;
    }
    .heading_fac {
        font-size: 3rem;
    }
    .snf_image img {
        justify-content: center;
        /* margin: auto; */
        width: 100vw;
        height: 300px;
        margin: 0;
    }
    .col-1 ul,
    .col-2 ul,
    .col-3 ul {
        gap: 1rem;
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
        align-items: flex-start;
        /* flex-direction: column; */
    }
    .box {
        flex: 0 0 calc(100px + 0px);
        height: 70px;
        padding: 10px;
        align-items: center;
    }
    .box-text {
        font-family: Lato;
        font-size: 11px;
        align-items: center;
        text-align: center;
    }
    .underlined-heading {
        margin-left: 1.25rem;
    }
}

@media (max-width: 450px) {
    .heading_fac {
        font-size: 2rem;
    }
}


/* Media queries Appointment box */

.get-appointment {
    margin-bottom: 00px;
    /* white-space: nowrap; */
}

@media (max-width: 1400px) {
    .get-appointment>p,
    .get-appointment>p#number {
        padding: 0px;
        font-size: 27px;
    }
    .get-appointment {
        padding: 20px;
    }
}

@media (max-width: 1080px) {
    .get-appointment>p,
    .get-appointment>p#number {
        padding: 0px;
        font-size: 22px;
    }
    .get-appointment {
        padding: 20px;
    }
}

@media (max-width: 385px) {
    .get-appointment>p,
    .get-appointment>p#number {
        padding: 0px;
        font-size: 18px;
    }
    .get-appointment {
        padding: 20px;
    }
}