/*Odometer */

.odometer-container {
    position: absolute;
    margin-top: 80px;
    width: 600px;
    height: auto;
    background-color: #0551A1;
    color: #ffffff;
    font-size: 200px;
    border-radius: 20px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.odometer{
    color: #ffffff;
}
.odometer-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px;
}

.odometer-content {
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    gap: 10px;
    margin-left: 20px;
    color: #ffffff;
}

.odometer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    border-color: red;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #E62020;
    color: #ffffff;
    /* margin-top: -200px; */
}

@media (max-width: 850px) {
    .odometer-container {
        align-items: center;
        width: auto;
        height: auto;
        font-size: 100px;
    }
    .odometer-content {
        font-size: 30px;
    }
    .odometer-button {
        width: 100px;
        height: 40px;
        font-size: 17px;
    }
}

@media (max-width: 400px) {
    .odometer-container {
        width: auto;
        height: auto;
        font-size: 50px;
        margin-left: 20px;
    }
    .odometer-content {
        font-size: 15px;
    }
    .odometer-button {
        width: 100px;
        height: 40px;
        font-size: 17px;
    }
}

@media (max-width: 500px) {
    .odometer-container {
        width: auto;
        height: auto;
        font-size: 50px;
        margin-left: 36px;
    }
    .odometer-content {
        font-size: 15px;
    }
}